import React, {useContext} from 'react'
import AutoMenu from './AutoMenu'
import {Link} from 'react-router-dom'
import Banner from './banner'
import LogoBlend from './svg/LogoBlend'
import LogoBar from './svg/LogoBar'
import Stout from './stout'
import Instagram from './icons/Instagram'
import Facebook from './icons/Facebook'
import Twitter from './icons/Twitter'
import './LandingPage.scss'
import Form from './Form'
import './WhatsNew.scss'
import ThemeContext from '../../Context/ThemeContext'
import Carousel from 'nuka-carousel';




const menuLinks = {
  walnut: [
    {name: "Family Meals TO GO",      link: '/public/walnut-creek/v4-family.pdf'},
    {name: "Meals Instructions",      link: '/public/walnut-creek/bierhaus-family-togo-instructions.pdf'},
    // {name: "Lunch Menu",      link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Lunch%20Menu.pdf'},
    // {name: "Dinner Menu",     link: '/public/walnut-creek/v17%20WC%20Bierhaus%20Dinner%20Menu.pdf'},
    // // {name: "Happy Hour Menu", link: '/public/walnut-creek/v2%20WC%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/walnut-creek/v16%20WC%20Beer%20Menu.pdf'},
    {name: "Beers TO GO",  link: '/public/walnut-creek/v3-Bottle-Menu.pdf'}
  ],
  oakland: [
    // {name: "Daily Menu",  link: '/public/oakland/v6-OAK-Limited-Bierhaus-Food-Menu.pdf'},
    // {name: "Happy Hour Menu",  link: '/public/oakland/L4%20Happy%20Hour%20Menu.pdf'},
    // {name: "Bier/Wine Menu",  link: '/public/oakland/v21-OAK-Beer-Menu.pdf'}
  ]
}

const gallery = [
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team.jpg'
  },

  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-1.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-2.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-3.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-4.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-5.png'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-food-samples-6.png'
  },

  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team-1.jpg'
  },
  {
    alt: '',
    url: 'https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/mikaza-team-2.jpg'
  },
]

export default function LandingPage () {
  const {themeName} = useContext(ThemeContext)
  const logoURL = (themeName === 'dark') ?
  "https://afag.imgix.net/tokyoya-sushi/logo-circle.png?w=500&auto=format" :
  "https://afag.imgix.net/tokyoya-sushi/logo-circle.png?w=500&auto=format"
  return(
    <div className="landing-page">
      <div className="header">
        <div className="hero-wrapper">
          <div className="hero-banner">
          {/* <Carousel
            className="carousel"
            autoplay={true}
            slideWidth={1}
            heightMode="first"
            autoplayInterval={5000}
            wrapAround={true}
            renderCenterRightControls={() => {
              return(
                null
              )
            }}
            renderCenterLeftControls={() => {
              return(
                null
              )
            }}
            renderBottomRightControls={() => {
              return(
                <Link to="/order/pab-chicken" className="order-online-button">
             We Are Closed
            </Link>
              )
            }}
            
            defaultControlsConfig={{
              nextButtonText: '→',
              prevButtonText: '←',
              nextButtonStyle: {
                textTransform: "inherit"
              },
              prevButtonStyle: {
                textTransform: "inherit"
              },

              
            }}
            >
      
            <img src="https://afag.imgix.net/pablitos-world/pizza.jpg?w=1200&auto=format"
              alt="mexican peruvian pizza"
             />
            <img src="https://afag.imgix.net/pablitos-world/la-clasica-burger-1.jpeg?w=1200&auto=format"
              alt="burger"
             />
            <img src="https://afag.imgix.net/pablitos-tacos-burbank/hero.jpeg?w=1200&auto=format"
              alt="tacos and burritos"
             />
                   <img src="https://afag.imgix.net/pablitos-world/12-chicken-2-sides.jpg?w=1200&auto=format"
              alt="pablitos chicken"
             />
          </Carousel> */}

            <div>
           
             
              {/* <Link to="/order" className="order-online-button">
              Order Pickup / Delivery
              <br/>
             
            </Link> */}
     
            </div>
          </div>
          {/* <div className="logo-banner">
            <img src={"https://afag.imgix.net/pablitos-world/logo.png?w=900&auto=format"}
             alt="Pablito's World"
             className="hero-logo"
           />
           
          </div> */}
        </div>


      </div>

      <div 
      style={{
        textAlign: 'center',
        padding: '2em 0.5em',
        fontSize: "1.5em"
      }}
      className="notice">

        <h1>Pablito's World is Closed</h1>

        <p>Find our other locations in Burbank, North Hollywood and Palmdale</p>
        <p>Visit <a href="https://pablitostacos.com">PablitosTacos.com</a> for information</p>

      </div>

      <div className="content">

        {/* <p
          style={{
            textAlign: 'center',
            margin: '1.5em 0.5em',
            fontWeight: "600",

          }}
          className="notice">NOTICE: We may have to close early afternoon so get in your order now</p> */}

        <div className="content-grid locations">
          <article className="location walnut">
            <div className="location-header">
              <h2 className="location-name">Los Angeles</h2>

              {/* <p className="subtitle">Burbank</p> */}

              <p className="address">
                <a className="address-link"
                  href="https://www.google.com/maps/place/686+N+Spring+St,+Los+Angeles,+CA+90012/@34.0595704,-118.239826,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c65b295a77cf:0x12a13c1c389204b!8m2!3d34.0595704!4d-118.237632">
                  686 N Spring St Los Angeles, CA 90012

                </a></p>
              <p className="phone">
                 <a
                   style={{fontWeight: 600}}
                   href="tel:+12133725898">(213) 372-5898</a>
              </p>



              {/* <div className="hours">
                <h3 className="hours-title">Dine-In / Pickup / Delivery</h3>
                <p>5pm - 9pm</p>

                <p>Mon-Thurs</p>
                <p>11am - 9pm</p>
                <p>Fri,Sat</p>
                <p>11am - 10pm</p>
                <p>Sunday</p>
                <p>11am-9pm</p>

        
              </div> */}
            </div>


          </article>






        </div>


      </div>

      <div className="whats-new">

       


        {/* <div className="in-the-news">

          <div className="article">
            <img 
            alt="LA Taco: A patriarchy-defying Latina sushi chef, a chef from argentina, and a peruvian taquero brings DTLA's first full Nikkei restaurant"
            src="https://afag.imgix.net/mikaza-nikkei-sushi-dtla-spring-arcade/la-tacos-review.png?w=800&auto=format" alt=""/>
            <a href="https://www.lataco.com/nikkei-dtla-japanese-peruvian/">read review</a>
          </div>

        </div> */}

        


        <Form></Form>

{/* 
        <div className="gallery">
          {gallery.map(({url, alt}) => {
            return (
              <img
               alt="sample food photo of Mikaza, menu coming later"
               className={"gallery-img"}
               src={`${url}?fit=crop&w=400&h=400`} alt=""/>
            )
          })}
        </div> */}

{/* 
        <div className="whats-new__content">

          <article className="full-spread slow cooked">

            <div className="main">
              <img src="https://afag.imgix.net/bierhaus/ribs-close-up.jpg?w=1200&auto=format" alt=""/>
            </div>

            <div className="text-box-wrapper">
                <div className="text-box">
                <h2>Slow-Cooked</h2>
                <p>Available warm or as reheat-at-home meal kits.</p>
              </div>
            </div>

          </article>



        </div> */}
{/*
        <div className="link-wrapper">
          <a href="/order?bottom" className="order-online-button">View Full Menu</a>
        </div> */}

      </div>

      <div className="menu">
        {/* <div className="menu-header">
          <h2>Our Menu</h2>
        </div> */}

        {/* <AutoMenu width={600}></AutoMenu> */}

        {/* <div className="beverage-menu-link-wrapper">
          <Link to="/drinks" className="order-online-button">Full Beverage Menu</Link>
        </div> */}
      </div>



      {/* <div className="content">
        <div className="content-grid">
          <div className="email-form">
            <EmailSubscribeForm></EmailSubscribeForm>
          </div>


          <div className="social-links">
            <a
              className="social-link"
              href="https://www.instagram.com/bierhausca/"><Instagram></Instagram>Instagram</a>
            <a
              className="social-link"
              href="https://www.facebook.com/bierhausca/"><Facebook></Facebook>Facebook</a>
            <a
              className="social-link"
              href="https://twitter.com/BierhausCA"><Twitter></Twitter>Twitter</a>
          </div>
        </div>
      </div> */}






      {/* <div className="landing-page-footer">
        <Stout></Stout>
      </div> */}
    </div>
  )
}
