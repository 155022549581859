
import * as Components from "./Pablitosworld"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "pablitosworld"
}

