import React from 'react'
import './Butler.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'

/*
  Call Staff Over - 

  Place first order with credit card

  Place second order - add to check 

*/

export default function () {
  return(
    <div className="page">

      <div className="assist-panel">
        <h1>Dine In Shortcuts</h1>

        <p>Flag down a staff, place a dine-in order instantly all from your phone.</p>

       

        <div className="button-group">
          <button className="assist-btn">
            Order / View Menu
            <div className="sub"></div>
          </button>
        </div>

        <h2>Get In-Person Help</h2>
        
        <div className="button-group">
          <button className="assist-btn">
            Request Staff
            <div className="sub">
              order / beverage refill / check
            </div>
          </button>
        </div>


      </div>

      
    </div>
  )
}
