import React from 'react'
import './MultishopMenus.scss'
import AutoMenu from '../../react/AutoMenu'
import {Link, NavLink} from 'react-router-dom'

const shops =  [
  {
    title: "Chicken",
    menuTag: "pab-chicken",
    banner: "",
    background: "",
    logo: "https://afag.imgix.net/pablitos-world/pablitos-chicken.png"
  },
  // {
  //   title: "Mikaza",
  //   menuTag: "pab-mikaza",
  //   banner: "",
  //   background: "https://afag.imgix.net/los-angeles-food-factory/curryme-bg.jpg?w=1200&auto=format",
  //   logo: "https://afag.imgix.net/pablitos-world/mikaza-express-1.png"
  // },
  {
    title: "Hamburguetza",
    menuTag: "hamburguetza",
    banner: "",
    background: "https://afag.imgix.net/los-angeles-food-factory/curryme-bg.jpg?w=1200&auto=format",
    logo: "https://afag.imgix.net/pablitos-world/hamburguetza-1.png"
  },
  {
    title: "Tacos",
    menuTag: "pab-tacos",
    banner: "",
    background: "",
    logo: "https://afag.imgix.net/pablitos-world/pablitos-tacos.png"

  },
  {
    title: "Pizza",
    menuTag: "pab-pizza",
    banner: "",
    background: "",
    logo: "https://afag.imgix.net/pablitos-pizza/logo-large.png"

  },

]



export default function ({match}) {
  const {menuTag} = match.params
  const pageStyles = {}
  let shopConfigs
  shops.forEach((config) => {
    if (config.menuTag === menuTag) {
      shopConfigs = config 
      return
    }
  })
  // if (shopConfigs.background) {
  //   pageStyles.background = `url(${shopConfigs.background}) no-repeat`
  // }
  return(
    <div className={"page " + menuTag}
      style={pageStyles}
    >
      <div
        className="page-header">

      {/* <div className="text">
          <h1>*Food from each menu can be ordered together</h1>
      </div> */}

  

        <div className="store-nav">
          {shops.map(({title, menuTag, banner, background, logo}) => {
            return(
              <div className="nav-item">
               
                <NavLink className="nav-link" to={`/order/${menuTag}`}>
                <div className="logo">
                  <img src={`${logo}?w=300&auto=format`} alt={title} />
                </div>
                  {/* {title} */}
                  </NavLink>
              </div>
            )
          })}
        </div>
      </div>

      <div
        style={{
          textAlign: 'center',
          paddingBottom: '1em'
        }}
        className="full-menu-link-wrapper">
        {/* <Link to="/order" className="button">Go to Full Menu</Link> */}
      <p>*choose concept from top</p>
      <p>*Food from each menu can be ordered together</p>
      {/* <p>Online Ordering coming soon...</p> */}
      </div>

      {(menuTag === 'pab-pizza-no') && 
         <div
         style={{
           textAlign: 'center',
           paddingBottom: '1em',
           fontSize: "2em"
         }}
         className="full-menu-link-wrapper">
       
       <p>Pizza Temporarily Unavailable</p>
       </div>
      }

      <AutoMenu 
      hideNav
      subMenuFilter={subMenuFilter}
      width={600} tagsToShow={[menuTag]} />

      <div
        style={{
          textAlign: 'center',
          paddingBottom: '1em'
        }}
        className="full-menu-link-wrapper">
        {/* <Link to="/order" className="button">Go to Full Menu</Link> */}
      </div>
    </div>
  )
}


function BBQSpecials () {

}

function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

 
  const spPromoEndTS = 1656752400000 // 2am 7-2
  const spPromoStartTS = 1656741300000 // 10:55pm 7-2
 
  if (tag.indexOf('pab-special-promo') !== -1) {
    if (Date.now() < spPromoStartTS) {
      return false
    }
    if (Date.now() > spPromoEndTS) {
      return false
    }
  }

  return true
}


export {shops}