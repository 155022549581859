
import React, { Component } from 'react';
import {Helmet} from "react-helmet";

const shops = {
    "pablitos-chicken": {
        title: "Pablito's Chicken",
        menuTag: "pab-chicken",
        description: "Wood-fired Peruvian Rotisserie chicken",
        banner: "",
        background: "",
        logo: "https://afag.imgix.net/pablitos-world/pablitos-chicken.png"
      },
      "mikaza-express": {
        title: "Mikaza Express",
        menuTag: "pab-mikaza",
        description: "Japanese Peruvian fusion sushi and bento boxes",
        banner: "",
        background: "https://afag.imgix.net/los-angeles-food-factory/curryme-bg.jpg?w=1200&auto=format",
        logo: "https://afag.imgix.net/pablitos-world/mikaza-express-1.png"
      },
      "pablitos-tacos": {
        title: "Pablito's Tacos",
        description: "Mexican Peruvian street tacos and more",
        menuTag: "pab-tacos",
        banner: "",
        background: "",
        logo: "https://afag.imgix.net/pablitos-world/pablitos-tacos.png"
    
      },
      "pablitos-pizza": {
        title: "Pablito's Pizza",
        description: "Mexican Peruvian Pizza",

        description: "",
        menuTag: "pab-pizza",
        banner: "",
        background: "",
        logo: "https://afag.imgix.net/pablitos-pizza/logo-large.png"
    
      },
}

export default function ({match}) {
//   console.log('our story rendering')

  const {id} = match.params
  const info = shops[id]
  return(
    <div className="location-page">
      <Helmet>
        <title>{info.title}</title>
        <meta name="description" content={info.description} />
      </Helmet>


    </div>
  )
}
